button {
    outline: none!important;
}

.field-rules-container {
    top: 0px;
    left: 0px;
    position: fixed;
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,0.2);
    z-index: 1350!important;
    
}
.field-rules-container > .field-rules-content {
    width: 35%;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100vh;
    overflow-y: auto;
    background: white;
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: linear;
    transition-delay: 1s;
    padding: 20px;
    box-shadow: -2px 0px 3px rgba(0,0,0,0.2);
    z-index: 1352!important;
    padding-bottom: 50px;
}
.field-rules-container > .field-rules-content button.cancel {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    box-shadow: none;
    color: #ccc;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
}
.field-rules-container > .field-rules-content button.cancel:hover {
    color: #333;
}
.field-rules-container > .field-rules-content h1 {
    display: block;
    font-size: 26px;
    margin-top: 40px;
    margin-bottom: 20px;
    position: relative;
}
.field-rules-container > .field-rules-content h1::before {
    position: absolute;
    top: -14px;
    left: 0px;
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(168, 168, 168);
    content:"VISIBILITY RULES FOR";
    font-weight: 300;
}
.field-rules-container > .field-rules-content ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
}
.field-rules-container > .field-rules-content ul li {
    display: block;
    margin-top: 20px;
    border: solid 1px #CCC;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    position: relative;
}
.field-rules-container > .field-rules-content ul li label {
    font-weight: bold;
}
.field-rules-container > .field-rules-content ul li.rule {
    padding-top: 40px;
}

.field-rules-container > .field-rules-content .match {
    color: rgb(175, 174, 174);
    text-transform: lowercase;
}
.field-rules-container > .field-rules-content .value {
    color: #333
}

.field-rules-container > .field-rules-content ul li .condition {
    position: absolute;
    top: 10px;
    left: 10px;
    text-transform: uppercase;
    color: rgb(14, 139, 241);
    font-weight: 500;
}
.field-rules-container > .field-rules-content ul li  .delete {
    position: absolute;
    top: 0px;
    right: 0px;
}

.field-rules-container > .field-rules-content ul li input[type="checkbox"] {
    appearance: none;
    width: 100px;
    border: solid 1px #CCC;
    border-radius: 20px;
    padding: 10px;
    height: 30px;
    position: relative;
    outline: none;
    cursor: pointer;
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]:checked {
    background: rgb(244, 253, 248);
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]:not(:checked) {
    background: rgb(207, 236, 250);
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]::after {
    position: absolute;
    color: rgb(92, 91, 91);
    font-size: 14px;
    font-weight: bold;
    
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]:checked::after {
    left: 40%;
    top: 4px;
    content: "AND";
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]:not(:checked)::after {
    right: 40%;
    top: 4px;
    content: "OR";
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]::before {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #FFF;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    content: "";
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]:checked::before {
    top: 5px;
    left: 5px;
    transition: position 2s ease-in-out;
}
.field-rules-container > .field-rules-content ul li input[type="checkbox"]:not(:checked)::before {
    top: 5px;
    right: 5px;
    transition: position 2s ease-in-out;
}
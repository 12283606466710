@import './assets/css/animate.css';
body
{
  background: #f1f1f1;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  width: 100%;
  height: 100%;
}
.screen
{
  display: block;
  width: 100%;
  min-height: 800px;
}
.zoom-image-container
{
  display: inline-block;
  margin: 20px;
  position: relative;
  margin-bottom: 70px;
}
.zoom-image-container .marker
{
  width: 60px;
  height: 60px;
  text-align: center;
  opacity: 0.8;
}
.zoom-image-container .note
{
  width: 300px;
  padding: 10px;
  background:#FFF;
  color:#333;
  font-size: 12px;
  min-height: 40px;
  text-align: justify;
  border:solid 5px #4C3E85;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
}


.zoom-image-container .note {
	position: relative;
	background: #FFF;
	border: 4px solid #4C3E85;
}
.zoom-image-container .note:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.zoom-image-container .note:after {
	border-color: #4C3E85;
	border-bottom-color: #4C3E85;
	border-width: 5px;
	margin-left: -5px;
}
.zoom-image-container .note:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #4C3E85;
	border-width: 11px;
	margin-left: -11px;
}
.zoom-image-container .marker.active
{
  opacity: 1;
}
.zoom-image-container .marker.active svg
{
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
 
}
.zoom-image-container .marker svg
{
  width: 100%;
  height: 100%;
}
.zoom-image-container .marker.red svg
{
  fill: #4C3E85;
}
.zoom-image-container .marker.green svg
{
  fill: green;
}
.zoom-image-container .marker.blue svg
{
  fill: blue;
}
.zoom-image-container.active
{
  cursor: crosshair;
}
.zoom-image-container img
{
  max-width: 100%;
  display: inline-block;
}
.screen.authentication
{
  background:url('assets/images/girl.png');
  background-position: 90% 100%;
  background-size: 400px 357px;
  background-repeat: no-repeat;
}
.cover.authentication
{
  /*background: rgba(76,62,133,0.95); 
  background: -webkit-linear-gradient(to right,rgba(76,62,133,0.95), rgba(57,152,216,0.7) );  
  background: linear-gradient(to right, rgba(76,62,133,0.95), rgba(57,152,216,0.7));  */
  background: #f1f1f1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.cover.authentication.loader
{
  /*background: rgba(76,62,133,1); 
  background: -webkit-linear-gradient(to right,rgba(76,62,133,1), rgba(57,152,216,1) ); 
  background: linear-gradient(to right, rgba(76,62,133,1), rgba(57,152,216,1));  */
}
.cover.authentication::before
{
  /*position: absolute;
  top: 20%;
  left: 0px;
  width: 443px;
  height: 402px;
  content:'';
  background:url('assets/images/overlay-bg.png');
  background-position: left;
  background-repeat: no-repeat;*/
}
.container.login
{
  padding-top: 300px;
  
}
.container.login
{
  color: #fafafa;
  text-align: justify;
  font-weight: 300;
  font-size: 13px;
}
.container.login.loading
{
  background: url('assets/images/logo-gray.png');
  background-position: 70% 50%;
  background-repeat: no-repeat;
  background-size:200px 131px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
button.tiny-delete
{
  width: 14px;
  height: 14px;
  background: url('./assets/images/delete.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  display: inline-block;
  border: none;
  margin-left: 10px;
}
button.tiny-delete:hover,button.tiny-delete:active
{
  background: url('./assets/images/delete-active.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
}
button.tiny-edit
{
  width: 14px;
  height: 14px;
  background: url('./assets/images/edit.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  display: inline-block;
  border: none;
  margin-left: 10px;
}
button.tiny-edit:hover,button.tiny-edit:active
{
  background: url('./assets/images/edit-active.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
}
.margin-bottom20px
{
  margin-bottom: 20px;
}
ul.attachments
{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
ul.attachments li
{
  display: block;
  margin-bottom: 3px;
}
ul.attachments li a
{
  font-size: 12px;
  color: #333;
  text-decoration: none;
  padding-left: 20px;
  background-image: url('assets/images/paper-clip.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
}
ul.attachments li a:hover
{
  opacity: 0.7;
}

.margin-top20px
{
  margin-top: 20px;
}
.mat-card
{
  padding: 20px;
  border-radius: 3px;
  position: relative;
}
.white-content
{
  color: #FFF!important;
}
.white-content svg
{
  fill:"#FFF"!important;
}
.image-toolbar
{
  position: absolute;
  bottom:0px;
  left: 20%;
  width: 60%;
  background: #fafafa;
  padding-top: 10px;
  padding-bottom: 5px;
  border-radius: 2px 2px 0px 0px;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
  height: 100px;
  z-index: 2;
}
.image-toolbar .disclaimer {
  display: block;
  font-size: 11px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  line-height: 12px;
}
.image-toolbar .disclaimer::before {
  content:"* "
}
.image-toolbar ul
{
  list-style: none;
  margin: 0px;
  padding:0px;
  text-align: center;
  position: relative;
}
.image-toolbar ul li
{
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 100%;
}
.note-taker-container
{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.3);
}
.note-taker-container .box
{
  position: absolute;
  top: 30%;
  left: 35%;
  width: 30%;
  background: #FFF;
  padding: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, .7);
  color: #333;
  font-size: 12px;
}
.note-taker-container .box h1
{
  color: #333;
  font-size: 18px;
  margin: 0px;
  padding:0px;
}
.note-taker-container .box textarea
{
  display: block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.note-taker-container .actions-container
{
  display: flex;
  flex-direction: row;
}
.note-taker-container .actions-container button:first-child
{
  align-self: flex-start;
  flex: 1;
}
.note-taker-container .actions-container button:last-child
{
  align-self: flex-end;
  flex: 1;
}
.image-toolbar ul li.right
{
  float: right!important;
}
.image-toolbar ul li.right svg
{
  font-size: 30px;
  margin-left: 0px;
}
.image-toolbar ul li.active
{
  border:solid 1px #333;
}
.image-toolbar ul li.first-color
{
  margin-right: 50px;
}
.image-toolbar ul li.control
{
  float: left;
  margin-left: 20px;
}
.image-toolbar ul li.tool-description {
  font-size: 11px;
  color: #666;
  width: 55%;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  float: left;
  padding-left: 20px;
  line-height: 12px;
  padding-top: 10px;
}
.image-toolbar ul li.tool-description select {
  width: 60%;
  display: inline-block;
}
.image-toolbar ul li button.btn {
  font-size: 12px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  margin-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 20px;
  font-weight: 500;
  margin-top: 5px;
}
.image-toolbar ul li button.btn-green {
  background: rgb(53, 202, 53);
  color: #FFF;
}
.image-toolbar ul li.color-container
{
  padding-top: 20px;
  float: right;
  margin-left: 10px;
  margin-right: 10px;
}
.image-toolbar ul li.color-container.selected .color
{
  border:solid 3px #999;
}
.image-toolbar ul li .color
{
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.image-toolbar ul li .color.red
{
  background: red;
}
.image-toolbar ul li .color.blue
{
  background: blue;
}
.image-toolbar ul li .color.green
{
  background: green;
}
.mat-card.white
{
  border: solid 1px #CCC;
  background: #fafafa;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
}
.container.login .mat-card::before
{
  position: absolute;
  top: -200px;
  left: 0px;
  width: 100%;
  height: 131px;
  background:url('assets/images/logo-gray.png');
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  background-size: 200px 131px;
}
.mat-card h1
{
  display: block;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 20px;
}
button.link
{
  background: transparent;
  border: none;
  outline:none!important;
  font-size: 12px;
  color: #333;
  cursor: pointer;
}
button.link:hover
{
  text-decoration: underline;
}
.mat-card .actions
{
  display: flex;
  justify-content: space-evenly;
}
.mat-card .actions .align-left
{
  flex: 1;
  text-align: left;
}
.btn-material
{
  box-shadow: 0px 1px 1px rgba(0,0,0,0.5);
  border-radius: 3px!important;
}
.text-white
{
  color: #FFF;
}
.text-light
{
  color: #fafafafa;
}
button.branded
{
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300!important;
  font-size: 14px!important;
}
.btn-primary
{
  background: #3B86B8!important;
  border-color:#3B86B8!important;
  outline: 0!important;
  color: #FFF!important;
  
}
.btn-primary:hover {
  background-color: #276d9b!important;
  border-collapse: #276d9b!important;
  -webkit-transition: background-color 1000ms linear!important;
  -ms-transition: background-color 1000ms linear!important;
  transition: background-color 1000ms linear!important;
}
.branded-dark-input
{
  border: solid 1px #3B86B8!important;
  background: #FFF!important;
  outline: none!important;
  font-weight: 300;
  font-size: 300;
  color: #333!important;
  padding-top: 3px;
  padding-bottom: 3px;
  display: block!important;
}
.branded-dark-input:focus,.branded-dark-input:active
{
  border-color:#3B86B8!important;
  background: #FFF!important;
  -webkit-transition: background-color 1000ms linear!important;
  -ms-transition: background-color 1000ms linear!important;
  transition: background-color 1000ms linear!important;
}
.branded-dark-input:disabled
{
  border-color:#3B86B8!important;
  background:#eeeeee!important;
}
.branded-dark-input::-moz-placeholder
{
  color: #a9a8a8!important;
}
.branded-dark-input::-webkit-input-placeholder
{
  color: #a9a8a8!important;
}
.branded-dark-input::placeholder
{
  color: #a9a8a8!important;
}
.branded-dark-input:-ms-input-placeholder
{
  color: #a9a8a8!important;
}
.branded-dark-input::-ms-input-placeholder 
{
  color: #a9a8a8!important;
}
.btn-primary:active {
  background-color: #276d9b!important;
  background-size: 100%!important;
  transition: background 0s!important;
  outline: 0!important;
}
@media(max-width:1024px)
{
  .cover.authentication::before
  {
    background:none!important;
  }
}
@media(max-width:414px)
{
  .screen.authentication
  {
    background-size: 180px 160px;
    background-position: 90% 70%;
  }
}
.close-icon
{
  color: #892a2a!important;
  position: absolute;
  top: 20px;
  right: 20px;
  margin-left: 20px;
}
.close-icon:hover
{
  background:none!important;
  font-weight: bold;
  color: #c32525!important;
}
.form-group
{
  margin-bottom: 20px;
}
.form-group.dark .input-error
{
  display: block;
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  color: #f6f8be;
}
.form-group.dark .input-error::before
{
  content:'* ';
}
p.info
{
  font-size: 16px;
}
.main-toolbar
{
  /*background: rgba(76,62,133,0.95); 
  background: -webkit-linear-gradient(to right,rgba(76,62,133,0.95), rgba(57,152,216,0.7) );  
  background: linear-gradient(to right, rgba(76,62,133,0.95), rgba(57,152,216,0.7)); */
  background:#3B86B8;
  position: relative;
}
.main-toolbar h1
{
  margin: 0px;
  padding: 0px;
  font-size: 18px;
}
.main-toolbar::before
{
  position: absolute;
  left: 28%;
  top: 0px;
  width: 100px;
  height: 69px;
  background: url('assets/images/navlogo-white.png');
  background-repeat: no-repeat;
  background-size: 80px 55px;
  content: '';
}
.main-toolbar button
{
  color: #FFF;
  outline: 0!important;
}
.main-toolbar button:hover
{
  color: #ccc!important;
  background: none!important;
}
.main-toolbar .menu-button
{
  outline: 0!important;
  border-radius: 100%!important;
  margin-left: -12px;
}
.main-toolbar .menu-button:hover
{
  border-radius: 100%!important;
  color: #ccc!important;
}
.space-filler
{
  flex:1 1;
}
ul[role="menu"] li a
{
  color: #333;
  text-decoration: none;
}
ul[role="menu"] li .icon
{
  display: inline-block!important;
  margin-right: 0px!important;
}
ul[role="menu"] li .text
{
  display: inline-block!important;
  text-align: left!important;
}
.main-toolbar img.avatar
{
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.sidebar
{
  width: 350px;
  margin-right: -20px!important;
}
.sidebar button
{
  color: #333;
  outline: 0!important;
}
.sidebar button:hover
{
  color: #999!important;
  background: none!important;
}
.sidebar header
{
  position: relative;
  display: block;
  padding-top: 80px;
  margin-bottom: 100px;
  padding-left: 20px;
}
.sidebar header button
{
  position: absolute;
  right: 20px;
  top: 0px;
}
.sidebar header img.avatar
{
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: inline-block;
}
.sidebar header h1
{
  font-size: 18px;
  color: #3B86B8;
  
  font-weight: 400;
  padding:0px;
  margin:0px;
  padding-top: 10px;
  margin-bottom: 2px;
}
.sidebar header h2
{
  font-size: 12px;
  color: #3B86B8;
  font-weight: 300;
  padding: 0px;
  margin: 0px;
}
.sidebar .icon
{
  display: inline-block;
  margin: 0px;
}
sidebar a
{
  color: #333;
  text-decoration: none;
}
.light-text,.light-text span
{
  display: inline-block!important;
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300!important;
}
section
{
  padding-top: 20px;
  display: block;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
}
section.wide
{
  padding-left: 2%;
  padding-right: 2%;
}
section header
{
  display: block;
  text-align: center;
  margin-bottom: 50px;
}

section h1
{
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 500!important;
  display: block;
  text-align: center;
  font-size: 28px;
}
section table
{
  width: 100%;
}
a.dashboard-button
{
  display: block;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.2);
  height: 100px;
  background: #3B86B8;
  background:#fafafa;
  text-decoration: none!important;
  color: #333;
  margin-bottom: 20px;
}
a.dashboard-button:hover
{
  color: #3B86B8;
}
a.dashboard-button h1
{
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-left: 0px!important;
  text-align: left;
}
a.dashboard-button h2
{
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
}
a.dashboard-button .icon
{
  width:48px;
  height: 48px;
}
a.dashboard-button:hover .icon
{
  fill: #3B86B8;
}

table td .action-button:hover
{
  color: #333!important;
}

table td .action-button
{
  min-width: auto!important;
}
a.action-button
{
  color: #333!important;
  opacity: 0.7;
}
a.action-button:hover
{
  color: #3B86B8!important;
  opacity: 1!important;
}
table td .action-button:disabled
{
  color: #CCC;
}

.width35pct
{
  width: 35%;
  max-width: 35%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width50pct
{
  width: 50%;
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width40pct
{
  width: 40%;
  max-width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width30pct
{
  width: 30%;
  max-width: 30%!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width10pct
{
  width: 10%;
  max-width: 10%!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width20pct
{
  width: 20%;
  max-width: 20%!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width15pct
{
  width: 15%;
  max-width: 15%!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width25pct
{
  width: 25%;
  max-width: 25%!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
section .floating-button
{
  position: fixed;
  z-index: 2;
  bottom: 15%;
  right: 1%;
  
}
section .settings-button
{
  position: fixed;
  z-index: 2;
  bottom: 25%;
  right: 1%;
}

@media (max-width:1024px) {
  section .floating-button
  {
    bottom: 2%;
    right:15%;
  }
  section .settings-button {
    bottom: 2%;
  right:25%;
  }
}
button
{
  outline: 0!important;
}
.alert-message
{
  min-width: 300px;
}
.alert-message p
{
  padding-right: 10px;
}
.alert-message .close-icon
{
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
section button.close-section
{
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300;
  font-size: 12px;
  text-decoration: none;
  color: #999;
  background:none;
  border:none;
  cursor: pointer;
}
section button.close-section::before
{
  content:"←";
  margin-right: 3px;
}
section button.close-section:hover
{
  color: #333;
}
.form-group.nice label
{
  display: block!important;
  color: #333;
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 600;
  font-size: 15px;
}
.form-group.nice .input-error
{
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300;
  font-size: 11px;
  color: #c32525;
}
.form-group.nice .input-error::before
{
  content:"* "
}
.clear
{
  display: block;
  clear: both;
}
.form-group.nice input,.form-group.nice textarea,.form-group.nice select
{
  background: #fafafa;
  outline: 0!important;
}
.list
{
  display: block;
  padding: 20px;
  border: solid 1px #CCC;
  border-radius: 3px;
}
table thead th
{
  background: #e6e6e8;
  color: #000!important;
}
table.light thead th
{
  background: #fafafa;
  color: #333!important;
}
.break
{
  clear: both;
  display: block;
  width: 100%;
}
.line-break
{
  clear: both;
  display: block;
  margin-bottom: 20px;
  width: 100%;
}
.small-line-break
{
  clear: both;
  display: block;
  margin-bottom: 1px;
  width: 100%;
}
button.indicator-button
{
    min-width: 100px!important;
    min-height: 40px!important;
}
.btn-dark
{
  background: #fafafa!important;
  border-color: #CCC!important;
}
.btn-dark i
{
  color: #999!important;
}
.btn-dark:hover i
{
  color: #333!important;
}
.icon-button i
{
  font-size: 18px!important;
}
#alert-dialog-title
{
  background-color: #4C3E85;
  color: #FFF!important;
  background-image: url('assets/images/navlogo.png');
  background-position: 90% 10px;
  background-repeat: no-repeat;
  background-size: 80px 55px;
  margin-bottom: 20px;
}
#alert-dialog-title h6
{
  color: #FFF;
}
input.select
{
  padding-right: 20px!important;
  position: relative!important;
  background-image: url('assets/images/down.svg')!important;
  background-position: 98% 10px!important;
  background-repeat: no-repeat!important;
  background-size: 14px 14px!important;
  cursor: pointer!important;
}
input.select.open
{
  background-image: url('assets/images/up.svg')!important;
  background-position: 98% 10px!important;
  background-repeat: no-repeat!important;
  background-size: 14px 14px!important;
}
.select-list
{
  max-height: 200px;
  overflow-y:auto;
}
.list-search
{
  padding: 5px;
  margin: 0px!important;
}
.list-search input
{
  border-radius: 0px!important;
  background:#FFF!important;
}
.list-result span,.nice-text,.nice-text span
{
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300!important;
  font-size: 16px!important;
  
}
.list-result
{
  border-bottom: solid 1px #CCC!important;
}
.list-result:first-child
{
  border-top: solid 1px #CCC!important;
}
.inactive-menu-item,.inactive-menu-item div, .inactive-menu-item span,.inactive-menu-item:hover,.inactive-menu-item div:hover .inactive-menu-item span:hover
{
  cursor: pointer!important;
  
}
.no-margin
{
  margin:0px!important;
}
.padding
{
  padding:10px;
}
.form-group.clearable
{
  position: relative!important;
}
button.form-clear
{
  position: absolute!important;
  border: none;
  background: #CCC;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  top: 10px;
  right: 30px;
  z-index: 1;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  cursor: pointer;
}
button.form-clear::before
{
  position: absolute;
  top: -1px;
  left: 5px;
  font-size: 12px;
  color: #FFF;
  content: "x";
}
button.form-clear:hover
{
  background: #4C3E85;
}
.max-height-200
{
  max-height: 200px!important;
  overflow-y:auto!important;
}
table,table thead,table tbody,table tfoot
{
  max-width: 100%!important;
}
.info-card
{
  display: block;
  border: solid 1px #CCC;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  min-height: 100px;
  padding: 20px;
}
.info-card header
{
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 20px;
}
.info-card header svg
{
  width: 48px;
  height: 48px;
  fill: #666;
}
.info-card section
{
  display: block;
  margin-bottom: 10px;
}
article
{
  color: #666;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
article label
{
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300!important;
  font-size: 12px!important;
  text-transform: uppercase;
  color: #999;
  display: block;
  margin: 0px;
}
h4
{
  display: block;
  margin-bottom: 20px!important;
  padding-bottom: 10px!important;
  border-bottom: dotted 1px;
  width: 100%;
}
a.dashboard-button.app
{
  background: #FFEFBA;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, #FFEFBA);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #FFEFBA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
a.dashboard-button.app svg
{
  fill: #4C3E85;
}
#alert-dialog-title h2
{
  color: #FFF!important;
}
.width70pct
{
  width: 70%;
}
.dialog-data
{
  display: block;
  height: 200px;
  border: solid 1px #CCC;
  border-radius: 5px;
  overflow: auto!important;
  padding: 20px;
}
tr.clickable-row
{
  cursor: pointer;
}
tr.clickable-row:hover td
{
  background: rgba(145,205,251,0.1);
}
tr.clickable-row:active td
{
  background: rgba(145,205,251,0.3);
}
.dialog-close
{
  
  float: right;
  font-size: 12px;
  color: rgba(76,62,133,0.4);
  text-decoration: none!important;
  border: none;
  background:none;
  cursor: pointer;
}
.dialog-close:hover
{
  color: rgba(76,62,133,0.7);
}
.dialog-close:active
{
  color: rgba(76,62,133,1);
}
.card
{
  padding: 20px;
}
.card header
{
  display: block;
  padding-bottom: 20px;
  border-bottom: solid 1px #CCC;
  margin-bottom: 20px;
}
.card header h1
{
  font-size: 22px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  font-weight: 500;
}
ul.patient-info
{
  list-style: none;
  margin: 0px;
  padding: 0px;
  text-align: left;
}
ul.patient-info li
{
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: #999;
  min-width: 20%;
}
ul.patient-info li label
{
  display: block;
  margin-bottom: 3px;
  font-weight: 600;
}
.large-lesion-creator
{
  text-align: center;
  position: relative;
  height: 640px;
  padding:20px;
  border: solid 1px #CCC;
  border-radius: 5px;
}
.small-lesion-creator
{
  text-align: center;
  position: relative;
  height: 340px;
  padding:20px;
  border: solid 1px #CCC;
  border-radius: 5px;
  background: #C6E5FC;
}
.large-lesion-creator .links,.small-lesion-creator .links
{
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 12px;
  line-height: 13px;
}
.icon
{
  color: #4C3E85;
}
.imageupload-select-container
{
  border:dashed 2px #4C3E85;
  cursor: pointer;
  border-radius: 10px;
  height: 150px;
  text-align: center;
  opacity: 0.9;
}

.fileupload-button
{
  display: block;
  border:dashed 2px #4C3E85;
  cursor: pointer!important;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  text-transform: uppercase;
  color: #4C3E85;
  margin-bottom: 20px;
}
.fileupload-button.file
{
  text-transform: none;
  font-weight: 400;
}
.fileupload-button button
{
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #FFF;
  border-radius: 100%;
  background:#4C3E85;
  opacity: 0.9;
}
.fileupload-button div.file-name
{
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileupload-button button:hover
{
  opacity: 1;
  cursor: pointer;
}
.fileupload-button.disabled
{
  opacity: 0.8;
}
.fileupload-button:not(.disabled):hover
{
  opacity: 0.7;
}
.fileupload-button svg
{
  float: left;
  fill:#4C3E85;
}
.fileupload-button div.progress-indicator
{
  float: left;
}

.imageupload-uploaded-image-container,.image-preview
{
  border:dashed 1px #4C3E85;
  border-radius: 10px;
  width: 100%!important;
  height: 154px!important;
  position: relative;
  padding: 2px;
}
.imageupload-uploaded-image-container img,.image-preview img
{
  width: 100%!important;
  height: 150px!important;
  border-radius: 10px;
}
.imageupload-uploaded-image-container button
{
  position: absolute;
  top: 0px;
  right: 0px;
}
.imageupload-select-container:hover
{
  opacity:0.6;
  border:dashed 4px #4C3E85;
}
.imageupload-select-container:active
{
  opacity: 1;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
}
.imageupload-select-container .placeholder
{

  padding-top: 50px;
  padding-left: 50px;
} 
.large-lesion-creator .links button,.small-lesion-creator .links button
{
  color: #4C3E85;
  font-weight: 600;
  cursor: pointer;
  padding-right: 5px;
  padding-left: 5px;
  background:none;
  border:none;
  text-decoration: underline;
}
.large-lesion-creator .links button.active,.small-lesion-creator .links button.active
{
  opacity: 0.5;
  text-decoration: none;
}
.large-lesion-creator .links button:first-child,.small-lesion-creator  .links button:first-child
{
  border-right: solid 1px #4C3E85;
}
.large-lesion-canvas
{
  display: inline-block;
  width: 300px;
  height: 600px;
}
.small-lesion-canvas
{
  display: inline-block;
  width: 90px;
  height: 300px;
}
.large-lesion-creator canvas
{
  position: absolute;
  top: 20px;
  left: 25%;
  z-index: 1;
  width: 300px;
  height: 600px;
}
.small-lesion-creator canvas
{
  position: absolute;
  top: 20px;
  left: 25%;
  z-index: 1;
  width: 150px;
  height: 300px;
}
.large-lesion-creator .drawing-board
{
  position: absolute;
  top: 20px;
  left: 25%;
  z-index: 2;
  width: 300px;
  height: 600px;
  background: transparent;
}
.small-lesion-creator .drawing-board
{
  position: absolute;
  top: 20px;
  left: 25%;
  z-index: 2;
  width: 150px;
  height: 300px;
  background: transparent;
}
.lesion
{
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background:rgba(59,134,184,0.5);
  box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
  position: absolute;
  top: 20%;
  left: 50%;
  font-size: 10px;
  font-weight: 600;
  color: #FFF;
  border:solid 1px #FFF;
  cursor: pointer;
  text-align: center;
}
.lesion.active
{
  background:rgba(59,134,184,1);
}
.navigation-button
{
  text-decoration: none!important;
}

.navigation-button span
{
  font-size: 16px!important;
  margin-left: 10px;
  margin-right: 20px;
  opacity: 0.8;
}
.navigation-button.active span {
  opacity: 1;
}
.form-control.inline {
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  border-radius: 0px!important;
}
.small-lesion-creator .drawing-board .lesion
{
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background:#3B86B8;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
  position: absolute;
  top: 20%;
  left: 50%;
  font-size: 10px;
  font-weight: 600;
  color: #FFF;
  border:solid 1px #FFF;
  cursor: pointer;
  opacity: 0.5;
  cursor: pointer
}
.custom-menu-item {
  display: block!important;
  min-width: 300px!important;

}
.custom-menu-item:hover {
  opacity: 0.6;
}

.menu-button {
  display: block!important;
  width: 100%!important;
  background-color: #fafafa!important;
  background-image: url('./assets/images/down.svg');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 98% 15px;
  border: solid 1px #ced4da!important;
  text-align: left!important;
  font-weight: 400!important;
  text-transform: none!important;
  font-size: 1rem!important;
  color: #495057!important;
}
.fitzpatrick-skin-i {
  background-color:#f4d0b1!important;
}
.fitzpatrick-skin-ii {
  background-color:#e7b48f!important;
}
.fitzpatrick-skin-iii {
  background-color:#d29e7c!important;
}
.fitzpatrick-skin-iv {
  background-color:#ba7750!important;
}
.fitzpatrick-skin-v {
  background-color:#a55d2b!important;
}
.fitzpatrick-skin-vi {
  background-color:#3c201d!important;
}
.small-lesion-creator .drawing-board .lesion.current
{
  opacity: 1
}
.small-lesion-creator .drawing-board .lesion:hover
{
  opacity: 0.8
}
ul.diagram-images
{
  list-style: none;
  margin: 0px;
  padding: 0px;
  text-align: center;
}
ul.diagram-images li
{
  display: inline-block;
  width: 30%;
  padding: 5px;
  padding-left: 3px;
  text-align: center;
  font-size: 10px;
  color: #3B86B8;
  font-weight: 300;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
ul.diagram-images li:hover
{
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}
ul.diagram-images li .date
{
  margin-top: 5px;
  display: block;
  font-size: 8px;
  font-weight: 600;
}
ul.diagram-images li .image-container
{
  position: relative;
  background: url('./assets/images/images.svg');
  width: 96px;
  height: 96px;
  display: inline-block;
  margin-bottom: 5px;
  background-size: 96px 96px;
  background-position: left;
  background-repeat: no-repeat;
}
ul.diagram-images li .image-container img
{
  width: 80px;
  height: 80px;
  border-radius: 2px;
  position: absolute;
  top: 3px;
  left: 8px;
}
ul.diagram-images li h1
{
  font-size: 13px;
  font-weight: 600!important;
  margin: 0px;
  padding:0px;
  margin-bottom: 3px;
  color: #3B86B8;
  font-family: 'Open Sans', sans-serif!important;
  text-decoration: underline;
}
h6.navigable button
{
  cursor: pointer;
  opacity: 0.8;
  float:right;
}
h6.navigable button:disabled
{
  opacity: 0.3;
  cursor: default;
}
p.notes
{
  padding: 0px;
  font-size: 11px;
  color: #333;
  font-family: 'Open Sans', sans-serif!important;
  font-size: 300;
}
h6.navigable button:not(:disabled):hover
{
  opacity: 0.9;
}
h6.navigable button:not(:disabled):active
{
  opacity: 1;
}
h6.navigable .next
{
  border: none;
  background:url('./assets/images/next.svg');
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 100%;
  margin-left: 10px;
}
h6.navigable .prev
{
  border: none;
  background:url('./assets/images/back.svg');
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 100%;
}
.lesion-title
{
  font-size: 14px;
  color: #666;
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 300;
}
ul.lesion-records
{
  display: inline-block!important;
  list-style: none;
  margin: 0px;
  margin-left: 20px;
  padding: 0px;
  
}
ul.lesion-records li
{
  display: inline-block!important;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: 'Open Sans', sans-serif!important;
  font-size: 11px;
  font-weight: 300;
  background: none;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  border: solid 1px rgba(59,134,184,0.1);
}
ul.lesion-records li b
{
  font-weight: 600;
}
ul.lesion-records li ul
{
  display: none;
}
ul.lesion-records li.active
{
  background: rgba(59,134,184,0.1);
  color: #3B86B8;
}
ul.lesion-records li.active b
{
  opacity: 0.7;
}
ul.lesion-records li ol
{
  list-style: none;
  margin: 0px;
  margin-left: 5px;
  padding:0px!important;
  display: inline-block;
  font-size: 10px;
  text-align: left!important;
  color: #333;
}
ul.lesion-records li ol li {
  border: none;
}
ul.lesion-records li.active ol li
{
  display: inline-block;
  margin: 0px!important;
  background:none;
  color: #3B86B8;
  font-weight: 600;
  text-decoration: underline;
}

ul.lesion-records li.active ol li.current
{
  opacity: 0.8;
  text-decoration: none;
}
ul.lesion-records li.active ol::before
{
  content:"("
}
ul.lesion-records li.active ol::after
{
  content:")"
}
h6
{
  font-size: 18px!important;
}
h7
{
  font-size: 16px!important;
}
ul.clinical-record
{
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
}
ul.clinical-record li
{
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
  font-weight: 300;
}
ul.clinical-record li label
{
  display: block;
  margin: 0px;
  padding: 0px;
}
ul.clinical-record li b
{
  font-weight: 600;
}

.lesion-image-container
{
  display: block;
  border: solid 1px #9bd5fc;
  border-radius: 5px;
  padding: 1px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  height: 100%;
}
.form-group.nice label:nth-child(2) span
{
  font-size: 12px
}
.form-group.nice label span:first-child
{
  float: left;
}
.form-group.nice label span:nth-child(2)
{
  padding-top: 15px;
}
.lesion-image-container b
{
  font-weight: 600;
}
.lesion-image-container p
{
  padding: 0px;
}
.lesion-image-container img
{
  width: 100%;
  height: 66%;
  border-radius: 5px 5px 0px 0px;
  opacity: 0.9;
}
.lesion-image-container div
{
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.lesion-image-container:hover
{
  cursor: pointer;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
}
ul.diagram-images li.active
{
  background:#2faaf2;
  border-radius: 5px;
  color: #FFF;
}
ul.diagram-images li.active h1
{
  color: #FFF;
}
.lesion-map
{
  position: relative;
  border: solid 1px #eeeeee;
  border-radius: 5px;
  display: inline-block;
}
.lesion-map .links
{
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}
.lesion-map .links button
{
  color: #3B86B8;
  font-weight: 600;
  cursor: pointer;
  padding-right: 5px;
  padding-left: 5px;
  background:none;
  border:none;
  text-decoration: underline;
  font-size: 12px;
}
.lesion-map .links button.active
{
  opacity: 0.5;
  text-decoration: none;
}
.lesion-map .links button:first-child
{
  border-right: solid 1px #4C3E85;
}
.lesion-map div.map
{
  position: absolute;
  background: url('./assets/images/body.svg');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}
.lesion-map div.map .dot
{
  position: absolute;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 100%;
}

.lesion-map .zoom
{
  position: absolute;
  bottom: 20px;
  left: 25%;
  width: 50%;
  z-index: 2;
  padding: 20px;
  background:transparent;
}
.inner-card
{
  background: rgba(57,152,216,0.1)!important;
  border:none!important;
  color: #333;
  height: auto;
}
.inner-card h6
{
  font-size: 16px!important;
  padding: 0px!important;
  margin: 0px!important;
}
p.details
{
  font-size: 12px;
  text-align: justify;
}
.divider
{
  display: block;
  background: #CCC;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.inner-card p
{
  font-size: 12px;
  text-align: justify;
}
.btn-branded
{
  background: #3B86B8;
  color: #FFF!important;
  opacity: 0.8;
}
.btn-branded2
{
  background: #333;
  color: #FFF!important;
  opacity: 0.8;
}
.btn-branded:not(:disabled):hover
{
  opacity: 0.7;
}
.btn-image-preview
{
  width: 48px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.btn-image-preview.partial
{
  opacity: 0.7;
}
.btn-branded2:not(:disabled):hover
{
  opacity: 0.7;
}
.btn-branded:not(:disabled):active,.btn-branded:not(:disabled):focus
{
  opacity: 1;
  outline: none!important;
}
.btn-branded:disabled
{
  opacity: 0.1;
}
.btn-branded2:not(:disabled):active,.btn-branded2:not(:disabled):focus
{
  opacity: 1;
  outline: none!important;
}
.btn-branded2:disabled
{
  opacity: 0.1;
}
.dotted-container
{
  border:dashed 2px rgba(76,62,133,0.4);
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  color: #333;
}
.dotted-container label
{
  display: block;
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  margin-bottom: 3px;
  color: #3B86B8;
}
.dotted-container button.delete
{
  border: none;
  background: #3B86B8;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: rgba(76,62,133,0.8);
  cursor: pointer;
}
.dotted-container button.delete:hover,.dotted-container button.delete:active,.dotted-container button.delete:focus
{
  background: #3B86B8;
  color: #FFF;
}
.search-results
{
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 200px;
  margin-top: 20px;
  position: relative;
}
.search-results.large
{
  border: solid 1px #CCC;
  padding: 20px;
  border-radius: 3px;
  height: 400px;
}
label.little
{
  display: block!important;
  font-size: 12px;
  font-weight: bold;
  color: #3B86B8;
  margin-bottom: 3px;
}
.alt-link
{
  font-size: 13px;
  color: #999;
}
.search-results ul
{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.search-results ul li.image
{
  display: inline-block;
  width: 30%;
  margin:1%;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.search-results ul li.image img
{
  width: 100%;
  height: 96px;
  border-radius: 3px;
  border: solid 2px #FFF;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}
.search-results ul li.image:hover img
{
  border-color:#3B86B8;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}
.search-results ul li.image.selected {
  border:solid 4px #3B86B8!important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}
.search-results ul li.image:active img
{
  opacity: 0.7;
}
.search-results ul li.image .date
{
  position: absolute;
  top:2px;
  left:2px;
  z-index: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background: rgba(76,62,133,0.8);
  color: #FFF;
  font-size: 7px;
}
.search-results .loading-indicator
{
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
.no-padding
{
  padding-left: 5px!important;
  padding-right: 5px!important;
}
.tiny
{
  font-size: 12px!important;
}
.search-results ul li.result
{
  display: block;
  background: rgba(244,242,251,0.5);
  box-shadow: 0px 0px 1px rgba(0,0,0,0.2);
  padding: 2px;
  margin-bottom: 3px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
  border: solid 1px #CCC;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
}
.search-results ul li.result:hover
{
  background:rgba(76,62,133,0.1);
}
.search-results ul li.result:active
{
  background:rgba(76,62,133,0.2);
}
.search-results ul li.result label
{
  display: block;
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  margin-bottom: 3px;
  color: #4C3E85;
}
.search-results ul li.no-results
{
  display: block;
  text-align: center;
  font-size: 13px;
  color: #333;
  padding-top: 50px;
}
.link-button
{
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #4C3E85;
  opacity: 0.8;
}
.link-button:hover,.link-button:active,.link-button:focus
{
  opacity: 1;
}
.input-group-append.white .input-group-text
{
  background:#FFF!important;
  border-left: none!important;
}
.input-borderless
{
  border-right: none!important;
}
.form-control:focus,.form-control:active,.form-control:hover
{
  outline: none!important;
}

@media (max-width:1024px)
{
  .image-toolbar ul li.right {
    float: none!important;
    position: absolute!important;
    top: -10px!important;
    right: -20px!important;
  }
  .main-toolbar::before
  {
    
    left: 15%;
  }
  .mobile-margin
  {
    margin-bottom: 20px;
  }
  .lesion
  {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  ul.patient-info li:first-child
  {
    display: block;
    margin-bottom: 20px;
  }
  .container.login.loading
  {
    background-position: 55% 40%;
  }
  section
  {
    padding-left: 2%;
    padding-right: 2%;
  }
  .dotted-container
  {
    font-size: 11px;
  }
  
}
@media (max-width:768px) {
  button.navigation-button {
    padding: 5px!important;
    margin-right: 0px!important;
  }
  .navigation-button span {
    font-size: 13px!important;
    margin-right: 0px!important;
  }
  .main-toolbar::before
  {
    width: 50px;
    height: 35px;
    background-size: 60px 42px;
    left: 26%;
    top: 10px;
  }
}
@media (max-width:414px)
{
  .main-toolbar h1
  {
    display: none;
  }
  .mobile-hidden
  {
    display: none;
  }
  .main-toolbar::before
  {
    
    left: 20%;
  }
}

.dialog-title-note {
  position: absolute;
  left: 25%;
  top:30px;
  width: 50%;
  font-size: 12px;
  line-height: 12px;
  color: #FFF;
  font-weight: 300!important;
}
.image-tools {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  z-index: 3;
  background: rgb(27, 27, 27);
  padding: 5px 10px 5px 10px;
  border-radius: 25px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
}
.image-tools button {
  
  font-size: 18px;
  border-radius: 100%;
  border: none;
  background: transparent;
  color: #f1f1f1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iframe-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
.html-text-block {
  display: block;
  padding: 20px;
  border: solid 1px #CCC;
  border-radius: 10px;
  background: rgba(204, 204, 204,0.1);
}
.iframe-container iframe {
  width: 100%;
  height: 70vh;
  padding: 20px;
  background: #CCC;
  margin-bottom: 20px;
}
.basic-button {
  padding: 0px!important;
  margin: 0px!important;
}
.basic-button:hover,.basic-button:active,.basic-button::before,.basic-button::after {
  background: none!important;
  box-shadow: none!important;
  opacity: 0.8;
  
}
.image-view-container {
  width: 100%!important;
  height: 65vh!important;
  text-align: center;
  margin: auto;
  overflow: auto;

}
.image-view-container img {
    max-width: 100%;
    max-height: 65vh;
}
@media (max-width:1024px) {
  .image-view-container {
    width: 95%!important;
    height: 60vh!important;
  }
  
}
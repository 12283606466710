.marker-toggle {
    position: relative;
    width: 120px;
    padding: 2px;
    border-radius: 20px;
    font-size: 8px;
    color: #666666;
    text-transform: uppercase;
    height: 20px;
    font-weight: 500;
    background: #dbdbdb;
    cursor: pointer;
}
.marker-toggle.checked {
    background: #4C3E85;
    color: #FFF;
}
.marker-toggle.checked span {
    position: absolute;
    left: 10px;
    top: 4px;
}
.marker-toggle.checked::after {
    position: absolute;
    width: 25px;
    height: 25px;
    top: -3px;
    right: -1px;
    background: #FFF;
    border-radius: 100%;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    content:"";
}

.marker-toggle.unchecked span {
    position: absolute;
    border-right-width: 5px;
    top: 4px;
    left: 30px;
}
.marker-toggle.unchecked::after {
    position: absolute;
    width: 25px;
    height: 25px;
    top: -3px;
    left: -1px;
    background: #FFF;
    border-radius: 100%;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    content:"";
}
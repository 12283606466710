.body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 550px;
    position: relative;
    min-width: 350px;
}
.body-container .controls {
    position: absolute;
    left: 0px;
    top: 20%;
    display: flex;
    flex-direction: column;
    min-width: 60px;
}

.body-container .controls div.button-container {
    margin-bottom: 20px;
    font-size: 9px;
    color: #333;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.body-container .controls div.button-container.disabled {
    opacity: 0.3;
}
.body-container .controls button {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #a1a0a0;
    text-align: center;
    border:solid 2px #FFF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
    outline: none;
    -webkit-outline:none;
    margin-bottom: 2px;
    background: #eeeeee;
    cursor: pointer;
}
.body-container .controls button:hover,.body-container .controls button:active {
    color: #FFF;
    background: #e5e4e4;
}

.body-container .body-side-switcher {
    
    position: absolute;
    top: 0px;
    right: 0px;
    width: 70px;
    height: 20px;
    z-index: 3;
    cursor: pointer;
    border-radius: 20px;
    border: solid 1px #fc5555;
    display: inline-block;
}
.body-container .body-side-switcher.back {
    border-color:#363ffb;
}
.body-container .body-side-switcher::before {
    position: absolute;
    left: 1px;
    top:1px;
    width:25px;
    height:16px;
    border-radius:10px;
    background: #fc5555;
    content:""
}

.body-container .body-side-switcher.back::before {
    left: 42px;
    background: #363ffb;
}
.body-container .body-side-switcher::after {
    position: absolute;
    left: 30px;
    top:2px;
    height:16px;
    color: #fc5555;
    content:"FRONT";
    font-size:9px;
    font-weight: 500;
}
.body-container .body-side-switcher.back::after {
    left: 10px;
    color: #363ffb;
    content:"BACK";
}
.body-container .mini-body {
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    top: 30px;
    right: 0px;
    width: 70px;
    height: 200px;
    z-index: 3;
    cursor: pointer;
}
.body-container .mini-body  .head {
    display: block;
    background: url('../../assets/images/body-parts/head.png');
    background-size: 13px 16px;
    background-position: center;
    background-repeat: no-repeat;
    height: 16px;
    width: 100%;
}
.body-container .mini-body  .head.active {
    background: url('../../assets/images/body-parts/head-active.png');
    background-size: 13px 16px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .head.active {
    background: url('../../assets/images/body-parts/head-active-back.png');
    background-size: 13px 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .neck {
    display: block;
    background: url('../../assets/images/body-parts/neck.png');
    background-size: 9px 5px;
    background-position: center;
    background-repeat: no-repeat;
    height: 5px;
    width: 100%;
}
.body-container .mini-body  .neck.active {
    background: url('../../assets/images/body-parts/neck-active.png');
    background-size: 9px 5px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .neck.active {
    background: url('../../assets/images/body-parts/neck-active-back.png');
    background-size: 9px 5px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .body-parts-container {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 550px;
    position: relative;
    align-items: center;
}
.body-container .body-part-selected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 550px;
}
.body-container .body-part .dot {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    color: #FFF;
    border:solid 1px #FFF;
    box-shadow: 0px 0px 2px #000;
    background: #3B86B8;
    display: inline-block;
    position: absolute;
    font-size: 7px;
    z-index: 5;
}
.body-container .dot.inactive {
    opacity: 0.5;
    cursor: pointer;
}
.body-container .dot.inactive:hover {
    opacity: 0.8;
}
.body-container .dot.active {

    cursor: pointer;
    background: #fc5555!important;
}
.body-container.back .dot.active {

    cursor: pointer;
    background: #363ffb!important;
}

.body-container .body-part-selected .dot {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    color: #FFF;
    border:solid 1px #FFF;
    box-shadow: 0px 0px 2px #000;
    background: #3B86B8;
    display: inline-block;
    position: absolute;
    font-size: 7px;
    z-index: 5;
}
.body-parts-container .body-part {
    cursor: pointer;
    position: relative;
}
.body-parts-container .body-part:hover {
    opacity: 0.7;
}
.body-parts-container .body-part:active {
    opacity: 1;
}
.body-parts-container .head {
    display: inline-block;
    width: 53.6px;
    height: 63px;
    background: url('../../assets/images/body-parts/head.png');
    background-size: 53.6px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-parts-container .head:active {
    background: url('../../assets/images/body-parts/head-active.png');
    background-size: 53.6px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .head:active {
    background: url('../../assets/images/body-parts/head-active-back.png');
    background-size: 53.6px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .head {
    width: 251px;
    height: 300px;
    background: url('../../assets/images/body-parts/head.png');
    background-size: 251px 300px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .neck {
    display: inline-block;
    width: 36.5px;
    height: 18px;
    background: url('../../assets/images/body-parts/neck.png');
    background-size: 36.5px 18px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-parts-container .neck:active {
    background: url('../../assets/images/body-parts/neck-active.png');
    background-size: 36.5px 18px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .neck:active {
    background: url('../../assets/images/body-parts/neck-active-back.png');
    background-size: 36.5px 18px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .neck {
    width: 300px;
    height: 153px;
    background: url('../../assets/images/body-parts/neck.png');
    background-size: 300px 153px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .chest {
    display: inline-block;
    width: 148px;
    height: 67px;
    background: url('../../assets/images/body-parts/chest.png');
    background-size: 148px 67px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-parts-container .chest:active {
    background: url('../../assets/images/body-parts/chest-active.png');
    background-size: 148px 67px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container.back .body-parts-container .chest:active {
    background: url('../../assets/images/body-parts/chest-active-back.png');
    background-size: 148px 67px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .chest {
    display: block;
    background: url('../../assets/images/body-parts/chest.png');
    background-size: 37px 17px;
    background-position: center;
    background-repeat: no-repeat;
    height: 17px;
    width: 100%;
}
.body-container .mini-body  .chest.active {
    background: url('../../assets/images/body-parts/chest-active.png');
    background-size: 37px 17px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .chest.active {
    background: url('../../assets/images/body-parts/chest-active-back.png');
    background-size: 37px 17px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .chest {
    width: 350px;
    height: 159px;
    background: url('../../assets/images/body-parts/chest.png');
    background-size: 350px 159px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .belly {
    display: inline-block;
    height: 63px;
    width: 80px;
    background: url('../../assets/images/body-parts/belly.png');
    background-size: 80px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-parts-container .belly:active {
    background: url('../../assets/images/body-parts/belly-active.png');
    background-size: 80px 63px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container.back .body-parts-container .belly:active {
    background: url('../../assets/images/body-parts/belly-active-back.png');
    background-size: 80px 63px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .belly {
    display: block;
    background: url('../../assets/images/body-parts/belly.png');
    background-size: 20px 16px;
    background-position: center;
    background-repeat: no-repeat;
    height: 16px;
    width: 100%;
}
.body-container .mini-body  .belly.active {
    background: url('../../assets/images/body-parts/belly-active.png');
    background-size: 20px 16px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .belly.active {
    background: url('../../assets/images/body-parts/belly-active-back.png');
    background-size: 20px 16px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .belly {
    width: 300px;
    height: 235px;
    background: url('../../assets/images/body-parts/belly.png');
    background-size: 256px 200px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .waist {
    display: inline-block;
    width: 92.8px;
    height: 63px;
    background: url('../../assets/images/body-parts/waist.png');
    background-size: 92.8px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-parts-container .waist:active {
    background: url('../../assets/images/body-parts/waist-active.png');
    background-size: 92.8px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .waist:active {
    background: url('../../assets/images/body-parts/waist-active-back.png');
    background-size: 92.8px 63px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .mini-body  .waist {
    display: block;
    background: url('../../assets/images/body-parts/waist.png');
    background-size: 23px 16px;
    background-position: center;
    background-repeat: no-repeat;
    height: 16px;
    width: 100%;
}
.body-container .mini-body  .waist.active {
    background: url('../../assets/images/body-parts/waist-active.png');
    background-size: 23px 16px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .waist.active {
    background: url('../../assets/images/body-parts/waist-active-back.png');
    background-size: 23px 16px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .waist {
    width: 300px;
    height: 205px;
    background: url('../../assets/images/body-parts/waist.png');
    background-size: 300px 205px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .left-bicep {
    height: 53px;
    width: 35px;
    background: url('../../assets/images/body-parts/biceps-left.png');
    background-size: 35px 53px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 148px;
    left: 98.5px;
}
.body-parts-container .left-bicep:active {
    background: url('../../assets/images/body-parts/biceps-left-active.png');
    background-size: 35px 53px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .left-bicep:active {
    background: url('../../assets/images/body-parts/biceps-left-active-back.png');
    background-size: 35px 53px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .mini-body  .left-bicep {
    display: block;
    background: url('../../assets/images/body-parts/biceps-left.png');
    background-size: 8.75px 14px;
    background-position: center;
    background-repeat: no-repeat;
    height: 14px;
    width: 8.75px;
    position: absolute;
    top: 48px;
    left:16.5px;
}
.body-container .mini-body  .left-bicep.active {
    background: url('../../assets/images/body-parts/biceps-left-active.png');
    background-size: 8.75px 14px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .left-bicep.active {
    background: url('../../assets/images/body-parts/biceps-left-active-back.png');
    background-size: 8.75px 14px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .left-bicep {
    width: 233px;
    height: 350px;
    background: url('../../assets/images/body-parts/biceps-left.png');
    background-size: 233px 350px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .right-bicep {
    height: 53px;
    width: 35px;
    background: url('../../assets/images/body-parts/biceps-right.png');
    background-size: 35px 53px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 148px;
    left: 215.5px;
}
.body-parts-container .right-bicep:active {
    background: url('../../assets/images/body-parts/biceps-right-active.png');
    background-size: 35px 53px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .right-bicep:active {
    background: url('../../assets/images/body-parts/biceps-right-active-back.png');
    background-size: 35px 53px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .right-bicep {
    display: block;
    background: url('../../assets/images/body-parts/biceps-right.png');
    background-size: 8.75px 14px;
    background-position: center;
    background-repeat: no-repeat;
    height: 14px;
    width: 8.75px;
    position: absolute;
    top: 48px;
    left:45.5px;
}
.body-container .mini-body  .right-bicep.active {
    background: url('../../assets/images/body-parts/biceps-right-active.png');
    background-size: 8.75px 14px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .right-bicep.active {
    background: url('../../assets/images/body-parts/biceps-right-active-back.png');
    background-size: 8.75px 14px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .right-bicep {
    width: 233px;
    height: 350px;
    background: url('../../assets/images/body-parts/biceps-right.png');
    background-size: 233px 350px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .left-forearm {
    height: 72px;
    width: 27.5px;
    background: url('../../assets/images/body-parts/forearm-left.png');
    background-size: 27.5px 72px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 200px;
    left: 98.5px;
}
.body-parts-container .left-forearm:active {
    background: url('../../assets/images/body-parts/forearm-left-active.png');
    background-size: 27.5px 72px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .left-forearm:active {
    background: url('../../assets/images/body-parts/forearm-left-active-back.png');
    background-size: 27.5px 72px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .mini-body  .left-forearm {
    display: block;
    background: url('../../assets/images/body-parts/forearm-left.png');
    background-size: 6.875px 19px;
    background-position: center;
    background-repeat: no-repeat;
    height: 19px;
    width: 6.875px;
    position: absolute;
    top: 62px;
    left:16.5px;
}
.body-container .mini-body  .left-forearm.active {
    background: url('../../assets/images/body-parts/forearm-left-active.png');
    background-size: 6.875px 19px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .left-forearm.active {
    background: url('../../assets/images/body-parts/forearm-left-active-back.png');
    background-size: 6.875px 19px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-part-selected .left-forearm {
    width: 175px;
    height: 465px;
    background: url('../../assets/images/body-parts/forearm-left.png');
    background-size: 175px 465px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .right-forearm {
    height: 72px;
    width: 27.5px;
    background: url('../../assets/images/body-parts/forearm-right.png');
    background-size: 27px 72px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 200px;
    left: 223px;
}
.body-parts-container .right-forearm:active {
    background: url('../../assets/images/body-parts/forearm-right-active.png');
    background-size: 27px 72px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .right-forearm:active {
    background: url('../../assets/images/body-parts/forearm-right-back.png');
    background-size: 27px 72px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .mini-body  .right-forearm {
    display: block;
    background: url('../../assets/images/body-parts/forearm-right.png');
    background-size: 6.875px 19px;
    background-position: center;
    background-repeat: no-repeat;
    height: 19px;
    width: 6.875px;
    position: absolute;
    top: 62px;
    left:47.5px;
}
.body-container .mini-body  .right-forearm.active {
    background: url('../../assets/images/body-parts/forearm-right-active.png');
    background-size: 6.875px 19px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .right-forearm.active {
    background: url('../../assets/images/body-parts/forearm-right-back.png');
    background-size: 6.875px 19px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .right-forearm {
    height: 465px;
    width: 175px;
    background: url('../../assets/images/body-parts/forearm-right.png');
    background-size: 175px 465px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .upper-left-leg {
    height: 74px;
    width: 45.2px;
    background: url('../../assets/images/body-parts/upper-left-leg.png');
    background-size: 45.2px 74px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 274px;
    left: 129px;
}
.body-parts-container .upper-left-leg:active {
    background: url('../../assets/images/body-parts/upper-left-leg-active.png');
    background-size: 45.2px 74px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container.back .body-parts-container .upper-left-leg:active {
    background: url('../../assets/images/body-parts/upper-left-leg-active-back.png');
    background-size: 45.2px 74px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .upper-left-leg {
    display: block;
    background: url('../../assets/images/body-parts/upper-left-leg.png');
    background-size: 11.3px 20px;
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    width: 11.3px;
    position: absolute;
    top: 80px;
    left:24px;
}
.body-container .mini-body  .upper-left-leg.active {
    background: url('../../assets/images/body-parts/upper-left-leg-active.png');
    background-size: 11.3px 20px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .upper-left-leg.active {
    background: url('../../assets/images/body-parts/upper-left-leg-active-back.png');
    background-size: 11.3px 20px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-part-selected .upper-left-leg {
    width: 243px;
    height: 400px;
    background: url('../../assets/images/body-parts/upper-left-leg.png');
    background-size: 243px 400px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .upper-right-leg {
    height: 74px;
    width: 45px;
    background: url('../../assets/images/body-parts/upper-right-left.png');
    background-size: 45px 74px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 274px;
    left: 176px;
}
.body-parts-container .upper-right-leg:active {
    background: url('../../assets/images/body-parts/upper-right-left-active.png');
    background-size: 45.2px 74px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .upper-right-leg:active {
    background: url('../../assets/images/body-parts/upper-right-leg-active-back.png');
    background-size: 45.2px 74px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .upper-right-leg {
    display: block;
    background: url('../../assets/images/body-parts/upper-right-left.png');
    background-size: 11.3px 20px;
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    width: 11.3px;
    position: absolute;
    top: 80px;
    left:36px;
}
.body-container .mini-body  .upper-right-leg.active {
    background: url('../../assets/images/body-parts/upper-right-left-active.png');
    background-size: 11.3px 20px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .upper-right-leg.active {
    background: url('../../assets/images/body-parts/upper-right-leg-active-back.png');
    background-size: 11.3px 20px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .upper-right-leg {
    width: 243px;
    height: 400px;
    background: url('../../assets/images/body-parts/upper-right-left.png');
    background-size: 243px 400px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .bottom-left-leg {
    height: 117px;
    width: 37.5px;
    background: url('../../assets/images/body-parts/bottom-left-leg.png');
    background-size: 37.5px 117px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 348px;
    left: 127.5px;
}
.body-parts-container .bottom-left-leg:active {
    background: url('../../assets/images/body-parts/bottom-left-leg-active.png');
    background-size: 37.5px 117px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .bottom-left-leg:active {
    background: url('../../assets/images/body-parts/bottom-left-leg-active-back.png');
    background-size: 37.5px 117px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .bottom-left-leg {
    display: block;
    background: url('../../assets/images/body-parts/bottom-left-leg.png');
    background-size: 9.375px 32px;
    background-position: center;
    background-repeat: no-repeat;
    height: 32px;
    width: 9.375px;
    position: absolute;
    top: 100px;
    left:23.5px;
}
.body-container .mini-body  .bottom-left-leg.active {
    background: url('../../assets/images/body-parts/bottom-left-leg-active.png');
    background-size: 9.375px 32px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .bottom-left-leg.active {
    background: url('../../assets/images/body-parts/bottom-left-leg-active-back.png');
    background-size: 9.375px 32px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-part-selected .bottom-left-leg {
    width: 159px;
    height: 500px;
    background: url('../../assets/images/body-parts/bottom-left-leg.png');
    background-size: 159px 500px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .bottom-right-leg {
    height: 117px;
    width: 37.5px;
    background: url('../../assets/images/body-parts/bottom-right-leg.png');
    background-size: 37.5px 117px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 348px;
    left: 185px;
}
.body-parts-container .bottom-right-leg:active {
    background: url('../../assets/images/body-parts/bottom-right-leg-active.png');
    background-size: 37.5px 117px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .bottom-right-leg:active {
    background: url('../../assets/images/body-parts/bottom-right-leg-active-back.png');
    background-size: 37.5px 117px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .mini-body  .bottom-right-leg {
    display: block;
    background: url('../../assets/images/body-parts/bottom-right-leg.png');
    background-size: 9.375px 32px;
    background-position: center;
    background-repeat: no-repeat;
    height: 32px;
    width: 9.375px;
    position: absolute;
    top: 100px;
    left:38px;
}
.body-container .mini-body  .bottom-right-leg.active {
    background: url('../../assets/images/body-parts/bottom-right-leg-active.png');
    background-size: 9.375px 32px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .bottom-right-leg.active {
    background: url('../../assets/images/body-parts/bottom-right-leg-active-back.png');
    background-size: 9.375px 32px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-part-selected .bottom-right-leg {
    width: 159px;
    height: 500px;
    background: url('../../assets/images/body-parts/bottom-right-leg.png');
    background-size: 159px 500px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .left-hand {
    height: 51px;
    width: 24.3px;
    background: url('../../assets/images/body-parts/hand-left.png');
    background-size: 24.3px 51px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 272px;
    left: 99px;
}
.body-parts-container .left-hand:active {
    background: url('../../assets/images/body-parts/hand-left-active.png');
    background-size: 24.3px 51px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .left-hand:active {
    background: url('../../assets/images/body-parts/hand-left-active-back.png');
    background-size: 24.3px 51px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .left-hand {
    display: block;
    background: url('../../assets/images/body-parts/hand-left.png');
    background-size: 6.075px 15px;
    background-position: center;
    background-repeat: no-repeat;
    height: 15px;
    width: 6.075px;
    position: absolute;
    top: 81px;
    left:16.2px;
}
.body-container .mini-body  .left-hand.active {
    background: url('../../assets/images/body-parts/hand-left-active.png');
    background-size: 6.075px 15px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .left-hand.active {
    background: url('../../assets/images/body-parts/hand-left-active-back.png');
    background-size: 6.075px 15px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-part-selected .left-hand {
    width: 238px;
    height: 500px;
    background: url('../../assets/images/body-parts/hand-left.png');
    background-size: 238px 500px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .right-hand {
    height: 51px;
    width: 24.3px;
    background: url('../../assets/images/body-parts/hand-right.png');
    background-size: 24.3px 51px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 272px;
    left: 226px;
}
.body-parts-container .right-hand:active {
    background: url('../../assets/images/body-parts/hand-right-active.png');
    background-size: 24.3px 51px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .right-hand:active {
    background: url('../../assets/images/body-parts/hand-right-active-back.png');
    background-size: 24.3px 51px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container .mini-body  .right-hand {
    display: block;
    background: url('../../assets/images/body-parts/hand-right.png');
    background-size: 6.075px 15px;
    background-position: center;
    background-repeat: no-repeat;
    height: 15px;
    width: 6.075px;
    position: absolute;
    top: 81px;
    left:48.2px;
}
.body-container .mini-body  .right-hand.active {
    background: url('../../assets/images/body-parts/hand-right-active.png');
    background-size: 6.075px 15px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .right-hand.active {
    background: url('../../assets/images/body-parts/hand-right-active-back.png');
    background-size: 6.075px 15px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-part-selected .right-hand {
    width: 238px;
    height: 500px;
    background: url('../../assets/images/body-parts/hand-right.png');
    background-size: 238px 500px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-parts-container .left-foot {
    height: 43px;
    width: 28px;
    background: url('../../assets/images/body-parts/left-foot.png');
    background-size: 28px 43px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 465px;
    left: 133px;
}
.body-part-selected .left-foot {
    width: 275px;
    height: 424px;
    background: url('../../assets/images/body-parts/left-foot.png');
    background-size: 275px 424px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}
.body-container .mini-body  .left-foot {
    display: block;
    background: url('../../assets/images/body-parts/left-foot.png');
    background-size: 7px 11px;
    background-position: center;
    background-repeat: no-repeat;
    height: 11px;
    width: 7px;
    position: absolute;
    top: 132px;
    left:25px;
}
.body-container .mini-body  .left-foot.active {
    background: url('../../assets/images/body-parts/left-foot-active.png');
    background-size: 7px 11px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .left-foot.active {
    background: url('../../assets/images/body-parts/left-foot-active-back.png');
    background-size: 7px 11px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-parts-container .left-foot:active {
    background: url('../../assets/images/body-parts/left-foot-active.png');
    background-size: 28px 43px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .left-foot:active {
    background: url('../../assets/images/body-parts/left-foot-active-back.png');
    background-size: 28px 43px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-parts-container .right-foot {
    height: 43px;
    width: 28px;
    background: url('../../assets/images/body-parts/right-foot.png');
    background-size: 28px 43px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 465px;
    left: 189px;
}
.body-parts-container .right-foot:active {
    background: url('../../assets/images/body-parts/right-foot-active.png');
    background-size: 28px 43px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .body-parts-container .right-foot:active {
    background: url('../../assets/images/body-parts/right-foot-active-back.png');
    background-size: 28px 43px;
    background-position: center;
    background-repeat: no-repeat;
}

.body-container .mini-body  .right-foot {
    display: block;
    background: url('../../assets/images/body-parts/right-foot.png');
    background-size: 7px 11px;
    background-position: center;
    background-repeat: no-repeat;
    height: 11px;
    width: 7px;
    position: absolute;
    top: 132px;
    left:39px;
}
.body-container .mini-body  .right-foot.active {
    background: url('../../assets/images/body-parts/right-foot-active.png');
    background-size: 7px 11px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-container.back .mini-body  .right-foot.active {
    background: url('../../assets/images/body-parts/right-foot-active-back.png');
    background-size: 7px 11px;
    background-position: center;
    background-repeat: no-repeat;
}
.body-part-selected .right-foot {
    width: 275px;
    height: 424px;
    background: url('../../assets/images/body-parts/right-foot.png');
    background-size: 275px 424px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
}

@media (max-width:1024px) {
    .body-container.with-controls {
        padding-bottom: 70px;
        margin-bottom: 70px;
    }
    .body-container.with-controls .controls {
        left: 0px;
        top: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .body-container.with-controls .controls div.button-container {
        margin-bottom: 0px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .body-container .body-part-selected {
        align-items: flex-start;
    }
}